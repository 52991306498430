import { Circle, Flex } from '@chakra-ui/react'
import { InstallAppTile } from 'InstallAppTile'
import { GroupSelectionStore, useGroupDetailsStore } from 'UseGroupSelectionStore'
import { RecentsFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import fuzzysearch from 'fuzzysearch'
import { CSSProperties, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { NavigateFunction, NavigateOptions } from 'react-router-dom'
import { getPlayerInitials } from 'ui/PlayerTile'
import { showSignInToEditDialog } from 'util/dialogUtils'
import { Colors } from '../Colors'
import { LazyScrollReviewTile, ReviewTile } from '../ReviewTile'
import { ReviewsStore } from '../UseRecentsReviewStore'
import { useToggleSwitch } from '../components/Toggle'
import { showDialog } from '../components/common/Dialog'
import { FirebaseDb } from '../components/common/Firebase'
import { PwaInstaller } from '../components/common/InstallPwa'
import { RoundButton } from '../components/common/RoundButton'
import searchIcon from '../icons/magnify_graph.png'
import plusButtonIcon from '../icons/plus_button.png'
import { useGroupDialog } from '../ui/ShowCreateGroupDialog'
import { DraggableScrollBox } from './DraggableScrollBox'
import { SearchInput } from './SearchInput'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import { useQueryStringStringify } from './common/utils/QueryString'
import { showAddReviewsToGroupDialog } from './showAddReviewsToGroupDialog'

export function GroupsList(
  props: {
    firebaseDb: FirebaseDb
    groupSelectionStore: GroupSelectionStore
    excludeGroupId?: string
    showPersonal?: boolean
  } & (
    | {
        navigate: (url: string, opts?: NavigateOptions) => void
        onGroupCreated?: (groupId: string) => void
        createNewTeamOnRender?: boolean
        hideCreateNewTeam?: undefined
        addGroupTileAuth: AddGroupTileAuth
      }
    | {
        hideCreateNewTeam: true
      }
  ),
) {
  const { value: createNewTeam } = useFeatureFlag('createNewTeam', false, true)
  return (
    <Flex bgColor={Colors.color_black}>
      <DraggableScrollBox
        handleScrollWheel={true}
        className='flex'
        direction={'horizontal'}>
        {props.showPersonal && (
          <PersonalGroupTile
            selected={props.groupSelectionStore.selectedGroupId === null}
            onClick={() => props.groupSelectionStore.toggle(null)}
          />
        )}
        {props.groupSelectionStore.groupIds
          .filter((it) => !props.excludeGroupId || it !== props.excludeGroupId)
          .map((groupId) => (
            <GroupTile
              key={groupId}
              firebase={props.firebaseDb}
              groupId={groupId}
              selected={props.groupSelectionStore.selectedGroupId === groupId}
              onClick={props.groupSelectionStore.toggle}
            />
          ))}
      </DraggableScrollBox>
      {createNewTeam && !props.hideCreateNewTeam && (
        <AddGroupTile
          firebaseDb={props.firebaseDb}
          auth={props.addGroupTileAuth}
          navigate={props.navigate}
          onGroupCreated={props.onGroupCreated}
          activateOnRender={props.createNewTeamOnRender}
        />
      )}
    </Flex>
  )
}

export function PersonalGroupTile(props: { selected?: boolean; onClick?: () => void }) {
  return (
    <Flex
      direction={'column'}
      alignItems={'center'}
      padding={'16px 8px'}
      maxWidth={80}
      gap={8}>
      <Circle
        size={40}
        color={'white'}
        fontSize={24}
        border={props.selected ? '2px solid white' : undefined}
        bg={Colors.color_playback_crimson}
        onClick={() => props.onClick?.()}>
        {<span>P</span>}
      </Circle>
      <span
        style={{
          color: 'white',
          fontFamily: 'LeagueSpartan, sans-serif',
          textAlign: 'center',
          maxHeight: 12,
          fontSize: 12,
          width: 50,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {'Personal'}
      </span>
    </Flex>
  )
}

export function GroupTile(props: {
  firebase: FirebaseDb
  groupId: string
  selected?: boolean
  onClick?: (groupId: string) => void
}) {
  const { groupDetails } = useGroupDetailsStore(props.firebase, props.groupId)
  return (
    <>
      {groupDetails && (
        <Flex
          direction={'column'}
          alignItems={'center'}
          padding={'16px 8px'}
          maxWidth={80}
          gap={8}>
          <Circle
            size={40}
            color={'white'}
            fontSize={24}
            border={props.selected ? '2px solid white' : undefined}
            bg={groupDetails?.color ?? Colors.color_playback_crimson}
            onClick={() => props.onClick?.(props.groupId)}>
            {groupDetails?.avatarUrl ?
              <img
                src={groupDetails.avatarUrl}
                style={{ width: 40, height: 40, borderRadius: '50%' }}
                className='object-cover object-center'
                alt={'team avatar'}
              />
            : <span>{getPlayerInitials(groupDetails)}</span>}
          </Circle>
          <span
            style={{
              color: 'white',
              fontFamily: 'LeagueSpartan, sans-serif',
              textAlign: 'center',
              maxHeight: 12,
              fontSize: 12,
              width: 50,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {groupDetails?.name}
          </span>
        </Flex>
      )}
    </>
  )
}
export type AddGroupTileAuth =
  | { user: undefined; signInToCreateGroupRedirect: string }
  | { user: User; signInToCreateGroupRedirect?: string }
export function AddGroupTile(props: {
  firebaseDb: FirebaseDb
  onGroupCreated?: (groupId: string) => void
  activateOnRender?: boolean
  navigate: (url: string, opts?: NavigateOptions) => void
  auth: AddGroupTileAuth
}) {
  const { showCreateNewGroupDialog } = useGroupDialog(props.firebaseDb, props.auth.user)

  const activated = useRef(false)

  const handleClick = useCallback(async () => {
    const auth = props.auth
    if (auth.user) {
      const result = await showCreateNewGroupDialog()

      if (result) {
        props.onGroupCreated?.(result.groupId)
      }
    } else
      showSignInToEditDialog({
        action: { title: 'create team', message: 'create a team' },
        navigateToSignIn: () =>
          props.navigate(
            '/signin?redirect=' + encodeURIComponent(auth.signInToCreateGroupRedirect),
          ),
      })
  }, [props, showCreateNewGroupDialog])

  useEffect(() => {
    if (props.activateOnRender && activated.current === false) {
      activated.current = true
      handleClick()
    }
  }, [handleClick, props.activateOnRender, showCreateNewGroupDialog])

  return (
    <Flex
      direction={'column'}
      p={'16px 10px'}
      gap={8}
      ml={'auto'}
      alignItems={'center'}
      boxShadow={'0 10px 10px rgb(0,0,0,0.5)'}
      backgroundColor={'#11181B'}>
      <Circle
        size={40}
        bg={'#252E40'}
        cursor={'pointer'}
        _hover={{ opacity: 0.8 }}
        onClick={handleClick}>
        <img
          draggable={false}
          src={plusButtonIcon}
          style={{ width: 20, height: 20 }}
          alt={'create new team'}
        />
      </Circle>
      <span
        style={{
          color: 'white',
          fontFamily: 'LeagueSpartan, sans-serif',
          textAlign: 'center',
          maxHeight: 12,
          fontSize: 12,
          whiteSpace: 'nowrap',
        }}>
        Create Team
      </span>
    </Flex>
  )
}

export function ReviewsList({
  user,
  firebaseDb,
  limit,
  reviewStore,
  style,
  pwaInstaller,
  groupSelectionStore,
  navigate,
}: {
  groupSelectionStore?: GroupSelectionStore
  pwaInstaller?: PwaInstaller | undefined
  style?: CSSProperties
  limit?: number
  user: User
  reviewStore: ReviewsStore
  firebaseDb: FirebaseDb
  navigate: (url: string, opts?: NavigateOptions) => void
}) {
  const { Toggle, checked: filterByMe } = useToggleSwitch(false)
  const { queryStringStringify } = useQueryStringStringify()
  const [showSearch, setShowSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    reviewStore.setFilterByMe(filterByMe)
  }, [filterByMe, reviewStore])

  const onDeleteReviewClicked = useCallback(
    (entry: RecentsFirebaseEntry) => {
      showDialog({
        title: 'Remove review',
        children: (Red) => (
          <>
            Are you sure you want to remove this review?
            <br />
            <br />
            <ReviewTile
              entry={entry}
              firebaseDb={firebaseDb}
            />
            <br />
            <br />
            This will remove the review from your list.
            <br />
            <Red>It may not be recoverable</Red> at a later time.
          </>
        ),
        positiveButtonProps: 'REMOVE',
        negativeButtonProps: 'CANCEL',
      }).then((confirmed) => {
        if (confirmed) {
          return reviewStore.deleteReview(entry)
        }
      })
    },
    [firebaseDb, reviewStore],
  )

  const { orderedReviews } = reviewStore
  const ref = useRef<HTMLDivElement>(null)
  return (
    <>
      <div
        className='relative left-0 z-[1] box-border flex w-[inherit] flex-row items-center justify-between
          bg-[rgba(53,58,68,0.94)] px-6 py-7 text-start text-[20px] text-white font-bold'
        style={style}>
        <div>Recent Reviews</div>
        <div className='flex flex-row items-center justify-between gap-3 text-[0.8em] font-semibold'>
          <Toggle height={12} />
          by me
        </div>
      </div>
      <div
        ref={ref}
        className={
          limit === undefined ?
            'relative flex flex-col gap-[30px] overflow-y-auto px-4 pb-4 pt-0'
          : 'relative flex flex-col gap-[30px] px-4 pb-4 pt-0'
        }>
        <InstallAppTile pwaInstaller={pwaInstaller} />
        <div className='flex flex-row flex-nowrap justify-center gap-4'>
          {!showSearch && (
            <RoundButton
              className='w-fit self-center'
              icon={searchIcon}
              alt={''}
              backgroundColor={Colors.color_playback_crimson}
              color={'white'}
              iconInvert={true}
              onClick={() => setShowSearch(true)}>
              Filter
            </RoundButton>
          )}
          {showSearch && (
            <SearchInput
              autoFocus={true}
              onKeyDown={(e) => setSearchTerm(e.currentTarget.value)}
              placeholder={'Enter a search term...'}
              submitText={'Filter'}
              onSubmit={(e) => setSearchTerm(e)}
              onClose={() => {
                setShowSearch(false)
                setSearchTerm('')
              }}
            />
          )}
          {groupSelectionStore?.selectedGroupId && !showSearch && (
            <RoundButton
              onClick={() =>
                groupSelectionStore?.selectedGroupId &&
                showAddReviewsToGroupDialog({
                  firebaseDb,
                  user,
                  destinationGroupId: groupSelectionStore.selectedGroupId,
                  navigate,
                }).then(async (selections) => {
                  await Promise.all(
                    selections.mapNotNull(async ({ reviewId, documentPermissions, groups }) => {
                      const filteredGroups = Object.entries(groups ?? {}).filter(
                        ([groupId, valid]) =>
                          valid && groupId !== groupSelectionStore?.selectedGroupId,
                      )
                      if (groupSelectionStore?.selectedGroupId) {
                        await groupSelectionStore.addReview(
                          reviewId,
                          groupSelectionStore?.selectedGroupId,
                          documentPermissions,
                        )

                        await Promise.all([
                          firebaseDb.getRef(`users/${user.uid}/reviews/owned/${reviewId}`).remove(),
                          firebaseDb
                            .getRef(`users/${user.uid}/reviews/visited/${reviewId}`)
                            .remove(),
                        ])

                        if (filteredGroups.length) {
                          await Promise.all(
                            filteredGroups.map((groupId) =>
                              Promise.all([
                                firebaseDb.getRef(`groups/${groupId}/reviews/${reviewId}`).remove(),
                                firebaseDb.getRef(`reviews/${reviewId}/groups/${groupId}`).remove(),
                              ]),
                            ),
                          )
                        }
                      }
                    }),
                  )
                })
              }>
              Import Reviews
            </RoundButton>
          )}
        </div>
        {orderedReviews === undefined && <ThreeDots className='w-full' />}
        {orderedReviews
          ?.filter(
            (entry) =>
              !searchTerm ||
              (entry.reviewId && fuzzysearch(searchTerm, entry.reviewId)) ||
              (entry.videoId && fuzzysearch(searchTerm, entry.videoId)) ||
              (entry.title &&
                fuzzysearch(searchTerm.toLocaleLowerCase(), entry.title?.toLocaleLowerCase())),
          )
          .map((review) => {
            return (
              <Fragment key={review.reviewId}>
                {ref.current && (
                  <LazyScrollReviewTile<RecentsFirebaseEntry>
                    order={(hasChanged) => (hasChanged ? 0 : 1)}
                    entry={{
                      ...review,
                      time: review.lastVisited,
                      timeLabel: 'Last visited',
                    }}
                    scrollableParent={ref}
                    href={queryStringStringify('/editor', {
                      group: groupSelectionStore?.selectedGroupId ?? undefined,
                      id: review.reviewId,
                      mode: review.mode || 'edit',
                    })}
                    user={user}
                    firebaseDb={firebaseDb}
                    onDelete={() => onDeleteReviewClicked(review)}
                  />
                )}
              </Fragment>
            )
          })}
      </div>
    </>
  )
}
