import { loadStripe } from '@stripe/stripe-js'
import { Price } from 'data/stripe/StripeProduct'
import { User } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { firebaseConfig } from 'services/FirebaseConfig'
import { FirestoreDb, getApp } from '../components/common/Firebase'
import { SessionCreateParams } from '../data/stripe/StripeSession'

type LoadCheckoutProps = {
  firestoreDb: FirestoreDb
  price: Price
  user: User
  quantity?: number
  metadata?: {
    [key: string]: string | number | null | undefined
  }
}

export const loadCheckout = async ({
  firestoreDb,
  price,
  user,
  quantity = 1,
  metadata,
}: LoadCheckoutProps) => {
  return new Promise(async (resolve, reject) => {
    const doc = await firestoreDb
      .collection('users')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add({
        mode: price.type === 'one_time' ? 'payment' : 'subscription',
        price: price.id,
        quantity,
        success_url: window.location.toString(),
        cancel_url: window.location.toString(),
        allow_promotion_codes: true,
        metadata: { uid: user.uid, ...metadata },
      } satisfies SessionCreateParams)

    doc.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data() as {
        error?: { message: string }
        sessionId?: string
      }

      if (error) {
        alert(`An error occurred: ${error.message}`)
        resolve(undefined)
        return
      }

      if (sessionId) {
        const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY
        if (!key && process.env.NODE_ENV === 'development') {
          console.error('Stripe public key is undefined')
        }
        const stripe = key ? await loadStripe(key) : undefined
        if (!stripe && process.env.NODE_ENV === 'development') {
          console.error('Stripe is undefined')
        }

        stripe?.redirectToCheckout({ sessionId })
        resolve(undefined)
      }
    })
  })
}

export const redirectCustomerPortal = async () => {
  try {
    const functions = getFunctions(getApp(firebaseConfig), 'us-central1')
    const createPortalLink = httpsCallable<{ returnUrl: string }, { url: string }>(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
    )

    const { data } = await createPortalLink({
      returnUrl: window.location.origin,
    })

    window.location.assign(data.url)
  } catch (error) {
    console.error(error)
  }
}
