import { DodgeballMeasureKeys } from '../templates/dodgeball/MeasureKeys'

export const NswdlDivs = [
  {
    title: 'Mens 1',
    key: 'mens1',
    match: (title: string) =>
      (title.toLocaleLowerCase().includes(' mens div 1') ||
        title.toLocaleLowerCase().includes(' mens 1')) &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Mens 2',
    key: 'mens2',
    match: (title: string) =>
      (title.toLocaleLowerCase().includes(' mens div 2') ||
        title.toLocaleLowerCase().includes(' mens 2')) &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Mixed 1',
    key: 'mixed1',
    match: (title: string) =>
      (title.toLocaleLowerCase().includes(' mixed div 1') ||
        title.toLocaleLowerCase().includes(' mixed 1')) &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Mixed 2',
    key: 'mixed2',
    match: (title: string) =>
      (title.toLocaleLowerCase().includes(' mixed div 2') ||
        title.toLocaleLowerCase().includes(' mixed 2')) &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Womens',
    key: 'womens',
    match: (title: string) =>
      title.toLocaleLowerCase().includes(' womens:') &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Cloth',
    key: 'cloth',
    match: (title: string) =>
      title.toLocaleLowerCase().includes(' cloth:') &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
] as const

export const NswdlDivGroups = [
  {
    title: 'All',
    key: 'all',
    match: (title: string) => !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Foam combined',
    key: 'foam',
    match: (title: string) =>
      !title.toLocaleLowerCase().includes(' cloth:') &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
  {
    title: 'Cloth combined',
    key: 'cloth_group',
    match: (title: string) =>
      title.toLocaleLowerCase().includes(' cloth:') &&
      !title.toLocaleLowerCase().includes('pre-round'),
  },
] as const

export const NswdlDivFilters = [...NswdlDivGroups, ...NswdlDivs]

export type NswdlDivFilter = (typeof NswdlDivFilters)[number]

export type LeaderboardEntry = {
  title: string | null
  groupColor?: string | null
  resultsHashesByDivision: {
    [divisionKey: string]: {
      [reviewId: string]: { [K in DodgeballMeasureKeys]?: string | null } | null | undefined
    }
  }
  metricSettings: {
    hiddenMetrics: DodgeballMeasureKeys[] | null
    statsTemplateKey: 'basic' | 'default' | 'kdr' | Exclude<string, 'basic' | 'default' | 'kdr'>
  }
  resultsByPlayer: LeaderboardResults
  // resultsByTeam: LeaderboardResults
}

export type LeaderboardResults = {
  [K in DodgeballMeasureKeys]?:
    | (FirebasePlayerGameResult & {
        dividers: ResultDividers
      })[]
    | undefined
}

export type FirebaseGroupLeaderboardEntry = FirebaseBaseLeaderboardEntry & {
  type?: undefined | 'private'
  publicLeaderboardId?: string | null
}

export type FirebasePublicLeaderboardEntry = FirebaseBaseLeaderboardEntry & {
  type: 'public'
  groupId: string
}
export type FirebaseBaseLeaderboardEntry = {
  title: string | null
  groupColor?: string | null
  metricSettings?: {
    hiddenMetrics?: DodgeballMeasureKeys[] | null
    statsTemplateKey: 'basic' | 'default' | 'kdr' | Exclude<string, 'basic' | 'default' | 'kdr'>
  } | null
  /**
   * @deprecated
   */
  resultsHashes?: {
    [reviewId: string]: {
      [teamNameHash: string]:
        | {
            [K in DodgeballMeasureKeys]?: string | null
          }
        | null
        | undefined
    }
  }

  /**
   * Used to keep compare if there are updates between public and group leaderboards.
   * Used to check when new emails need to be sent
   */
  resultsHashesByDivision?: {
    [divisionKey: string | '_']: {
      [reviewId: string]: {
        [teamNameHash: string]:
          | {
              [K in DodgeballMeasureKeys]?: string | null
            }
          | null
          | undefined
      }
    }
  }

  resultsByPlayer: FirebaseLeaderboardResults
  // resultsByTeam: FirebaseLeaderboardResults
}
export type FirebaseLeaderboardResults = {
  [K in DodgeballMeasureKeys]?: {
    [nameHash: string]: {
      [pushKey: string]: FirebasePlayerGameResult & {
        dividers: ResultDividers
      }
    }
  } | null
}
export type FirebasePlayerGameResult = {
  player: string
  playerId: string | undefined
  title: string | undefined
  reviewId: string
  value: number | null
  displayValue: string | null
  displayBreakdown: string | null
  team?: number | null
  teamName?: string | null
  opponentName?: string | null
}

export type ResultDividers = {
  matchingDivisions?: Omit<(typeof NswdlDivFilters)[number], 'match'>[] | null
  division?: { key: string; title: string } | null
  round: RoundInfo
}

export type RoundInfo = { name: string; number: number; category: string }
