import { Box } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { WithOutContext as ReactTags, Tag } from 'react-tag-input'
import { EventDefinition, resolveStringOrFn } from 'templates/TemplateConfig'
import { ActivityTemplates } from 'templates/TemplateLibrary'
import { Colors } from '../Colors'
import { LayeredIconView } from '../components/common/LayeredIconView'
import { RoundButton } from '../components/common/RoundButton'
import searchIcon from '../icons/magnify_graph.png'
import './EventFilterBar.css'
import { ReviewActivityType } from './ReviewMetaStore'

const ENABLE_TAG_SEARCH = true

export function useEditorEventFilterBar(
  activityType: ReviewActivityType | undefined,
  watchMode: boolean,
) {
  const initialEventFilter = useMemo(() => {
    const newUrl = new URL(window.location.href)
    return newUrl.searchParams.getAll('event')
  }, [])
  // useEffect(() => {
  //   const newUrl = new URL(window.location.href)
  //   if (newUrl.searchParams.has('event')) {
  //     newUrl.searchParams.delete('event')
  //     window.history.replaceState({ path: newUrl.toString() }, '', newUrl.toString())
  //   }
  // }, [])
  return useEventFilterBar(activityType, !watchMode, initialEventFilter)
}

export function useEventFilterBar(
  activityType: ReviewActivityType | undefined,
  canEdit: boolean,
  initialEventFilter?: string[],
) {
  const [tagKeys, setTagKeys] = React.useState<string[]>(initialEventFilter ?? [])
  const [minimizeInput, setMinimizeInput] = React.useState<boolean>(true)
  const eventDefinitions: EventDefinition[] = useMemo(() => {
    if (!activityType) return []
    return tagKeys.flatMap((key) => ActivityTemplates[activityType].definitions.get(key) || [])
  }, [tagKeys, activityType])

  const EventFilterBarInternal = useCallback(
    ({ className, canEdit: overrideCanEdit }: { className?: string; canEdit?: boolean }) => {
      if (!activityType) return <></>
      const suggestions = ActivityTemplates[activityType].definitions
        .valuesArray()
        .map<Tag>((def) => {
          return {
            id: def.key,
            text: resolveStringOrFn(def.title),
          } satisfies Tag
        })
      const defaultSuggestions = ActivityTemplates[
        activityType
      ].defaultHighlightSuggestions?.map<Tag>((def) => {
        return {
          id: def.key,
          text: resolveStringOrFn(def.title),
        } satisfies Tag
      })

      const KeyCodes = {
        comma: 188,
        enter: 13,
      }
      const tagsSelected = tagKeys.mapNotNull((key) => suggestions.find((def) => def.id === key))
      const delimiters = [KeyCodes.comma, KeyCodes.enter]
      const handleDelete = (i: number) => {
        setTagKeys(tagKeys.filter((tag, index) => index !== i))
      }

      const handleAddition = (tag: Tag) => {
        setTagKeys([...tagKeys, tag.id])
      }

      const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
        const newTags = tagKeys.slice()

        newTags.splice(currPos, 1)
        newTags.splice(newPos, 0, tag.id)

        // re-render
        setTagKeys(newTags)
      }

      const handleTagClick = (index: number) => {
        console.log('The tag at index ' + index + ' was clicked')
      }
      const renderSuggestion = (tag: Tag, query: string) => {
        const def = ActivityTemplates[activityType].definitions.get(tag.id)

        if (!def) return <></>
        return (
          <Box
            width={'150px'}
            height={30}
            position={'relative'}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: '8px',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                // justifyContent: 'flex-end',
                position: 'absolute',
              }}>
              <LayeredIconView
                className='flex-shrink-0'
                src={def?.icon}
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: def?.color,
                  borderRadius: 9999,
                }}
              />
              <div
                style={{
                  fontFamily: 'LeagueSpartan, sans-serif',
                  fontWeight: '500',
                  minWidth: 0,
                  whiteSpace: 'break-spaces',
                }}>
                {resolveStringOrFn(def.title)}
              </div>
            </div>
          </Box>
        )
      }

      const handleFilterInputTriggerButtonClick = () => {
        setMinimizeInput(false)
      }

      const handleFilterInputBlur = () => {
        setMinimizeInput(true)
      }
      const shouldShowSearch = ENABLE_TAG_SEARCH && (overrideCanEdit ?? canEdit)
      return (
        <div
          className={`pointer-events-none flex flex-row items-center justify-center gap-2 [&>*]:pointer-events-auto
            ${className}`}>
          <ReactTags
            handleInputBlur={handleFilterInputBlur}
            tags={tagsSelected}
            suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition='inline'
            renderSuggestion={renderSuggestion}
            placeholder={'Filter events'}
            handleFilterSuggestions={(query, suggestions) => {
              if (query.length <= 3) {
                return defaultSuggestions ?? []
              }

              return suggestions.filter((suggestion) => {
                return suggestion.text.toLowerCase().includes(query.toLowerCase())
              })
            }}
            classNames={{
              tagInput: !minimizeInput && shouldShowSearch ? 'rounded-sm inline-block' : 'hidden',
              tagInputField:
                'border border-gray-200 p-2 m-0 text-xs min-w-[150px] font-league-spartan',
            }}
            minQueryLength={1}
          />
          {minimizeInput && shouldShowSearch ?
            <RoundButton
              icon={searchIcon}
              translate={'no'}
              alt={'filter events'}
              backgroundColor={Colors.color_playback_crimson}
              color={'white'}
              iconInvert={true}
              onClick={handleFilterInputTriggerButtonClick}
              squashedChildrenFallback={['Filter']}>
              Add Event Filter
            </RoundButton>
          : undefined}
        </div>
      )
    },
    [tagKeys, minimizeInput, canEdit, activityType],
  )

  const setEventDefinitions = useCallback(
    (eventDefintions: EventDefinition[]) => {
      setTagKeys(eventDefintions.mapProp('key'))
    },
    [setTagKeys],
  )

  return useMemo(
    () => ({
      EventFilterBar: EventFilterBarInternal,
      eventDefinitions,
      setEventDefinitions,
    }),
    [EventFilterBarInternal, eventDefinitions, setEventDefinitions],
  )
}
