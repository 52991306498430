import { Flex } from '@chakra-ui/react'
import { GroupSelectionStore, useGroupSelectionStore } from 'UseGroupSelectionStore'
import { AvatarDisplay } from 'components/UploadAvatar'
import { FirebaseGroupDetails } from 'data/common'
import { User } from 'firebase/auth'
import { useCallback } from 'react'
import ReactTagManager from 'react-ga4'
import { redirectCustomerPortal } from 'util/LoadCheckout'
import { FocusSpan, showDialog } from '../components/common/Dialog'
import { EditableStringDiv } from '../components/common/EditableStringDiv'
import { FirebaseDb } from '../components/common/Firebase'
import { generateRandomColor } from './PlayerStore'

export function useGroupDialog(
  firebaseDb: FirebaseDb,
  user: User | undefined,
): {
  showCreateNewGroupDialog: () => Promise<
    Awaited<ReturnType<GroupSelectionStore['createNewGroup']>>
  >
  showDeleteGroupDialog: (hasSubscription: boolean) => Promise<boolean | undefined>
} {
  const randomColor = generateRandomColor()
  const groupSelectionStore = useGroupSelectionStore({
    firebaseDb,
    user: user ?? undefined,
  })

  const showCreateNewGroupDialog = useCallback(() => {
    ReactTagManager.event('create_new_group_dialog_shown', { category: 'group' })
    return new Promise<Awaited<ReturnType<GroupSelectionStore['createNewGroup']>>>((resolve) => {
      showDialog<FirebaseGroupDetails>({
        title: 'Create new team',
        positiveButtonProps: {
          text: 'Create',
          disabled: (groupState) => !groupState?.name,
          onClicked: async (groupState) => {
            ReactTagManager.event('create_new_group_dialog_create_clicked', { category: 'group' })
            if (!groupState?.name) return false

            const result = await groupSelectionStore.createNewGroup({
              ...groupState,
              color: groupState?.color ?? randomColor,
            })
            resolve(result)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: () => true,
        },
        onDismiss: () => resolve(undefined),
        children: (Red, groupState, setGroupState) =>
          GroupDialogBody(randomColor, groupState, setGroupState),
      })
    })
  }, [groupSelectionStore, randomColor])

  const showDeleteGroupDialog = useCallback(
    (activeSubscription: boolean) => {
      ReactTagManager.event('delete_group_dialog_shown', { category: 'group' })
      return new Promise<Awaited<boolean | undefined>>((resolve) => {
        showDialog(
          {
            title: 'Delete Team',
            positiveButtonProps: {
              text: 'Delete',
              disabled: (groupState) => groupState?.confirmText !== 'delete team',
              onClicked: async (groupState) => {
                ReactTagManager.event('delete_group_dialog_create_clicked', { category: 'group' })
                if (groupState?.confirmText !== 'delete team') return false

                return true
              },
            },
            negativeButtonProps: {
              text: 'Cancel',
              onClicked: () => true,
            },
            onDismiss: () => resolve(undefined),
            children: (Red, state, setState) => (
              <DeleteGroupDialogBody
                Red={Red}
                setState={setState}
              />
            ),
          },
          {
            confirmText: '',
          },
        ).then(async (value) => {
          if (!value) return

          await showDialog<{ loading: boolean }>({
            title: 'Warning!!',
            children: (Red) => (
              <>
                {activeSubscription ?
                  'You have an active subscription. You must cancel it before deleting this team'
                : 'This is a destructive operation and we may not be able to recover the team'}
              </>
            ),
            positiveButtonProps: {
              text: (state) =>
                activeSubscription ?
                  state?.loading ?
                    'Loading...'
                  : 'Redirect'
                : 'Delete',
              onClicked: async (state, setState) => {
                if (state?.loading) return false
                if (activeSubscription) {
                  setState({ loading: true })
                  await redirectCustomerPortal()
                } else {
                  await groupSelectionStore.deleteGroup()
                  window.location.assign('/')
                }
                return true
              },
            },
            negativeButtonProps: 'Cancel',
          })
        }),
          { loading: false }
      })
    },
    [groupSelectionStore],
  )

  return {
    showCreateNewGroupDialog,
    showDeleteGroupDialog,
  }
}

function GroupDialogBody(
  randomColor: string,
  groupState: FirebaseGroupDetails | undefined,
  setGroupState: React.Dispatch<React.SetStateAction<FirebaseGroupDetails | undefined>>,
) {
  return (
    <div className='flex flex-col items-center justify-center gap-5'>
      <AvatarDisplay
        src={groupState?.avatarUrl}
        backgroundColor={randomColor}
        onAvatarChange={(url) => setGroupState((state) => ({ ...state, avatarUrl: url }))}
        onBackgroundColorChange={(color) => setGroupState((state) => ({ ...state, color }))}
      />
      <EditableStringDiv
        placeholder={'Team short name'}
        onChange={(value) => setGroupState((state) => ({ ...state, name: value?.trim() }))}
      />
    </div>
  )
}

function DeleteGroupDialogBody({
  Red,
  setState,
}: {
  Red: FocusSpan
  setState: React.Dispatch<
    React.SetStateAction<{
      confirmText: string
    }>
  >
}) {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={20}>
      <div>
        <Red>Permanently delete this team</Red> and transfer all the reviews back to their creators.
      </div>

      <div>
        Type the words <Red>delete team</Red> to confirm you want to permanently delete this team.
      </div>

      <EditableStringDiv
        onChange={(confirmText) => {
          setState({ confirmText })
        }}
      />
    </Flex>
  )
}
