import { Flex, FlexProps, Image } from '@chakra-ui/react'
import { ViewingMode } from 'data/ViewingMode'
import React, { CSSProperties } from 'react'
import { isMobile } from 'react-device-detect'
import { VideoController, useVideoPlayerStatus } from '../components/VideoController'
import close_icon from '../icons/close_button.png'
import exit_fullscreen_icon from '../icons/exit_fullscreen_button.png'
import fullscreen_icon from '../icons/fullscreen_button.png'
import previous_icon from '../icons/previous_button.png'
import replay_icon from '../icons/replay_button.png'
import replay_highlight_icon from '../icons/replay_highlight_icon.png'
import share_icon from '../icons/share_social_button.png'
import skip_icon from '../icons/skip_button.png'
import square_icon from '../icons/square.png'
import stop_watch_icon from '../icons/stop_watch_button.png'
import watch_icon from '../icons/watch_button.png'
import { HighlightPlayer, HighlightTypes } from './HighlightsPlayer'

export type HighlightsMode = 'Spotlight' | 'Highlights'

function getHighlightsModeDescription(highlightsMode: HighlightsMode) {
  switch (highlightsMode) {
    case 'Spotlight':
      return 'Spotlight (PRO)'
    case 'Highlights':
      return 'Highlights'
  }
}

export type ViewModeButtonProps = {
  viewingMode: ViewingMode
  exitEditingMode?: () => void
}

export function HighlightsButtons(props: {
  height?: number
  videoController: VideoController | undefined
  isPlayingHighlights: false | HighlightTypes
  hasPlayersOrFilters: boolean
  highlightPlayer: HighlightPlayer
  displayStyle: 'large' | 'small' | 'smallest'
  highlightsMode: HighlightsMode
  fullscreenClicked: () => void
  watchMode: boolean
  onWatchModeClicked: () => void
  canExitWatchMode: boolean
  isFullScreen: boolean
  style?: CSSProperties
  disableBookmarkPlaybackControls?: boolean
  viewingModeButtonProps?: ViewModeButtonProps
}) {
  const canStartOver = useVideoPlayerStatus(
    props.videoController,
    30,
    false,
    (state) => state.currentTime > 5,
  )
  const { height = 40 } = props
  return (
    <Flex
      height={height}
      gap={'4px'}
      padding={'0 8px'}
      alignItems={'center'}
      style={props.style}>
      <HighlightsButton
        height={height - 4}
        src={replay_icon}
        disabled={!canStartOver}
        alt={'Start video from beginning'}
        onClick={props.highlightPlayer.startOver}
      />

      {(props.isPlayingHighlights === 'highlights' ||
        (props.isPlayingHighlights !== 'bookmarks' &&
          props.hasPlayersOrFilters &&
          props.viewingModeButtonProps?.viewingMode === 'view')) && (
        <HighlightsButton
          height={height - 4}
          src={props.isPlayingHighlights ? square_icon : skip_icon}
          alt={
            props.isPlayingHighlights ?
              `Stop watching ${getHighlightsModeDescription(props.highlightsMode)}`
            : `Skip to ${getHighlightsModeDescription(props.highlightsMode)}`
          }
          onClick={props.highlightPlayer.toggleMatchHighlights}
          text={
            props.isPlayingHighlights ?
              (props.displayStyle === 'large' ? 'Stop watching' : 'Stop') +
              ' ' +
              getHighlightsModeDescription(props.highlightsMode)
            : (props.displayStyle === 'large' ? 'Skip to' : 'See') +
              ' ' +
              getHighlightsModeDescription(props.highlightsMode)
          }
        />
      )}
      {props.watchMode && !props.disableBookmarkPlaybackControls && (
        <>
          <HighlightsButton
            height={height - 4}
            src={previous_icon}
            alt={`Back to previous bookmark`}
            onClick={() => {
              props.highlightPlayer.skipToLastBookmark()
              if (!props.watchMode) {
                props.onWatchModeClicked()
              }
            }}
            disabled={!props.highlightPlayer.hasLastBookmark}
          />
          <HighlightsButton
            height={height - 4}
            src={skip_icon}
            alt={`Skip to next bookmark`}
            onClick={() => {
              props.highlightPlayer.skipToNextBookmark()
              if (!props.watchMode) {
                props.onWatchModeClicked()
              }
            }}
            disabled={!props.highlightPlayer.hasNextBookmark}
            text={'Next bookmark'}
          />
        </>
      )}
      {/*{props.isPlayingHighlights === 'bookmarks' && props.watchMode && (*/}
      {/*  <BookmarkPlaybackControls*/}
      {/*    height={height - 4}*/}
      {/*    displayStyle={props.displayStyle}*/}
      {/*    onWatchModeClicked={props.onWatchModeClicked}*/}
      {/*    isPlayingHighlights={props.isPlayingHighlights}*/}
      {/*    highlightPlayer={props.highlightPlayer}*/}
      {/*    watchMode={props.watchMode}*/}
      {/*  />*/}
      {/*)}*/}
      {props.viewingModeButtonProps &&
        props.viewingModeButtonProps.viewingMode === 'edit' &&
        props.viewingModeButtonProps.exitEditingMode && (
          <HighlightsButton
            height={height - 4}
            src={close_icon}
            alt={'present mode'}
            onClick={props.viewingModeButtonProps.exitEditingMode}
            text={'Stop editing'}
          />
        )}
      {((props.canExitWatchMode && props.watchMode) || !props.watchMode) &&
        (!props.viewingModeButtonProps || props.viewingModeButtonProps.viewingMode !== 'edit') && (
          <HighlightsButton
            height={height - 4}
            src={props.watchMode ? stop_watch_icon : watch_icon}
            alt={'present mode'}
            onClick={props.onWatchModeClicked}
            text={
              props.displayStyle === 'large' ?
                props.watchMode ?
                  'Exit Presenting Mode'
                : 'Enter Presenting Mode'
              : props.watchMode ?
                'Exit Presenting Mode'
              : 'Present'
            }
          />
        )}
      <HighlightsButton
        height={height - 4}
        src={props.isFullScreen ? exit_fullscreen_icon : fullscreen_icon}
        alt={'fullscreen mode'}
        onClick={props.fullscreenClicked}
      />
    </Flex>
  )
}

export function BookmarkPlaybackControls(props: {
  height?: number
  watchMode: boolean
  isPlayingHighlights: false | 'highlights' | 'bookmarks'
  highlightPlayer: HighlightPlayer
  onWatchModeClicked: () => void
  displayStyle: 'large' | 'small' | 'smallest'
}) {
  return (
    <>
      <HighlightsButton
        height={props.height}
        src={replay_highlight_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={'Start highlight from beginning'}
        onClick={props.highlightPlayer.startOverCurrentHighlight}
      />

      <HighlightsButton
        height={props.height}
        src={previous_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={`Back to previous bookmark`}
        onClick={() => {
          props.highlightPlayer.skipToLastBookmark()
          if (!props.watchMode) {
            props.onWatchModeClicked()
          }
        }}
        disabled={!props.highlightPlayer.hasLastBookmark}
      />
      <HighlightsButton
        height={props.height}
        src={skip_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={`Skip to next bookmark`}
        onClick={() => {
          props.highlightPlayer.skipToNextBookmark()
          if (!props.watchMode) {
            props.onWatchModeClicked()
          }
        }}
        disabled={!props.highlightPlayer.hasNextBookmark}
        text={props.displayStyle === 'large' ? 'Next bookmark' : 'Next'}
      />
      <HighlightsButton
        height={props.height}
        backgroundColor={'rgba(70,108,242,0.9)'}
        src={close_icon}
        alt={'Stop playing bookmarks'}
        onClick={props.highlightPlayer.stopPlayingHighlights}
        text={'Stop playing bookmarks'}
      />
    </>
  )
}
export function HighlightsButton({
  alt,
  onClick,
  src,
  text,
  disabled,
  height = 36,
  ...flexProps
}: {
  height?: number
  src: string
  alt: string
  onClick: () => void
  text?: string
  disabled?: boolean
} & Omit<FlexProps, 'onClick'>) {
  const padding = (height * 8) / 36
  const fontPadding = (height * 20) / 36
  return (
    <Flex
      borderRadius={999}
      _hover={{ filter: disabled || isMobile ? undefined : 'brightness(120%)' }}
      _active={{ filter: disabled ? undefined : 'brightness(120%)' }}
      boxSizing={'border-box'}
      backgroundColor={'rgba(252,56,75,0.9)'}
      color={'white'}
      style={{
        cursor: disabled ? undefined : 'pointer',
        padding: padding,
        backgroundColor: disabled ? 'rgba(115,115,115,0.9)' : undefined,
        color: disabled ? '#b0b0b0' : undefined,
        opacity: disabled ? '0.6' : undefined,
        height,
        fontSize: height - fontPadding,
      }}
      alignItems={'center'}
      justifyContent={'center'}
      onClick={!disabled ? onClick : undefined}
      fontWeight={500}
      pointerEvents={'auto'}
      fontFamily={'LeagueSpartan, sans-serif'}
      gap={'8px'}
      whiteSpace={'nowrap'}
      {...flexProps}>
      <Image
        style={{
          height: height - padding * 2,
          width: height - padding * 2,
        }}
        objectFit={'contain'}
        boxSizing={'border-box'}
        src={src}
        alt={alt}
      />
      {text}
    </Flex>
  )
}

export function WatcherHighlightsButtons(props: {
  height?: number
  videoController: VideoController | undefined
  highlightPlayer: HighlightPlayer
  highlightsMode: HighlightsMode
  fullscreenClicked: () => void
  isFullScreen: boolean
  style?: CSSProperties
  shareReelClicked: () => void
}) {
  const canStartOver = useVideoPlayerStatus(
    props.videoController,
    30,
    false,
    (state) => state.currentTime > 5,
  )
  const { height = 40 } = props
  return (
    <Flex
      height={height}
      gap={'4px'}
      padding={'0 8px'}
      alignItems={'center'}
      style={props.style}>
      <HighlightsButton
        height={height - 4}
        src={replay_icon}
        disabled={!canStartOver}
        alt={'Start video from beginning'}
        onClick={props.highlightPlayer.startOver}
      />
      <HighlightsButton
        height={height - 4}
        src={share_icon}
        alt={'Share this video'}
        onClick={props.shareReelClicked}
        text={'Share this reel'}
      />

      <HighlightsButton
        height={height - 4}
        src={props.isFullScreen ? exit_fullscreen_icon : fullscreen_icon}
        alt={'fullscreen mode'}
        onClick={props.fullscreenClicked}
      />
    </Flex>
  )
}
