import { Flex, Input } from '@chakra-ui/react'
import { useGroupDetailsStore, useGroupSelectionStore } from 'UseGroupSelectionStore'
import {
  DocumentPermissions,
  FirebaseGroupDetails,
  RecentsFirebaseEntry,
  ReviewFirebaseEntry,
} from 'data/common'
import { matchingStatRecordTeamRules } from 'data/statrecordtypes'
import { User } from 'firebase/auth'
import { getStatRecord } from 'hooks/UseStatRecordReviewId'
import objectHash from 'object-hash'
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { NavigateOptions } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useReviewMetaStore } from 'ui/ReviewMetaStore'
import { useGroupPermissionsStore } from 'useGroupPermissionsStore'
import { Colors } from '../Colors'
import { ReviewTile } from '../ReviewTile'
import { useRecentsReviewStore } from '../UseRecentsReviewStore'
import { showDialog } from '../components/common/Dialog'
import { FirebaseDb } from '../components/common/Firebase'
import { RoundButton } from '../components/common/RoundButton'
import searchIcon from '../icons/magnify_graph.png'
import { MultiSelect, MultiSelectOption } from './MultiSelectOption'
import { GroupsList } from './ReviewsList'
import { ScrollBox } from './ScrollBox'
import { SearchInput } from './SearchInput'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import { usePromiseState } from './common/utils/reactUtils'
import { cn } from './common/utils/tailwindUtils'

export type ExistingReviewSelectionState = {
  selection:
    | {
        reviewId: string
        documentPermissions: DocumentPermissions
        strategy: StatRecordApplyStrategy
      }
    | { strategy: 'create_new' }
    | undefined
}

export function showProcessExistingReviewsForVideoSourceDialog(props: {
  firebaseDb: FirebaseDb
  reviews: RecentsFirebaseEntry[]
  statsRecordId: string | undefined
  user: User | undefined
}): Promise<ExistingReviewSelectionState> {
  return new Promise<ExistingReviewSelectionState>((resolve, reject) => {
    showDialog<ExistingReviewSelectionState>(
      {
        title: 'Existing Review',
        user_dismissable: true,
        children: (Red, state, setState, triggerPositiveButton, focusPositiveButton, dismiss) => (
          <>
            <Red>You already have a review for this video.</Red>
            <br />
            <br /> Would you like to {props.statsRecordId ?
              'attach the stats record to'
            : 'open'}{' '}
            an existing review or create a new one?
            <br />
            <ScrollBox
              direction={'vertical'}
              className='max-h-[60svh]'>
              <ExistingReviewsList
                reviews={props.reviews}
                selectedReviewId={
                  state?.selection?.strategy !== 'create_new' ?
                    state?.selection?.reviewId
                  : undefined
                }
                statsRecordId={props.statsRecordId}
                user={props.user}
                firebaseDb={props.firebaseDb}
                onSelected={(selection) => {
                  if (selection.intentionLevel === 'implicit') setState({ selection })
                  else {
                    triggerPositiveButton({ selection })
                  }
                }}
              />
            </ScrollBox>
          </>
        ),
        positiveButtonProps: {
          disabled: (state) => !state?.selection,
          text: (state) => {
            const strategy = state?.selection?.strategy
            switch (strategy) {
              case 'link':
                return 'Attach Stats'
              case 'replace':
                return 'Replace Stats'
              case 'upgrade_link':
                return 'Upgrade Stats'
              default:
                return 'Open'
            }
          },
          onClicked: (state, setState) => {
            resolve(state)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Create New Review',
          onClicked: (state, setState) => {
            resolve({ selection: { strategy: 'create_new' } })
            return true
          },
        },
        onDismiss: () => {
          resolve({ selection: undefined })
        },
      },
      { selection: undefined },
    )
  })
}

type ReviewSelection = {
  reviewId: string
  documentPermissions: DocumentPermissions
  groups?: { [id: string]: boolean }
}

export async function showAddReviewsToGroupDialog(props: {
  firebaseDb: FirebaseDb
  destinationGroupId: string
  user: User
  navigate: (url: string, opts?: NavigateOptions) => void
}): Promise<ReviewSelection[]> {
  const reviewSelection = await new Promise<ReviewSelection[]>((resolve, reject) => {
    showDialog<{
      reviews: ReviewSelection[]
      errors: string[]
    }>(
      {
        title: 'Import Reviews',
        children: (
          FocusSpan,
          state,
          setState,
          triggerPositiveButton,
          focusPositiveButton,
          dismiss,
        ) => (
          <AddReviewsToGroupContent
            destinationGroupId={props.destinationGroupId}
            user={props.user}
            firebaseDb={props.firebaseDb}
            navigate={props.navigate}
            onSelectionChanged={(a) => {
              setState((it) => ({ ...it, reviews: a }))
            }}
          />
        ),
        positiveButtonProps: {
          text: (state) => `Import ${state.reviews?.length ? state.reviews.length : ''} Reviews`,
          disabled: (state) => !state || !state.reviews.length,
          onClicked: async (state, setState) => {
            if (!state.reviews) return false

            const reviewsMap = await Promise.all(
              state.reviews.map(async (it) => ({
                ...it,
                groups:
                  (await props.firebaseDb.getVal<ReviewFirebaseEntry['groups']>(
                    `reviews/${it.reviewId}/groups`,
                  )) ?? undefined,
              })),
            )

            resolve(reviewsMap)
            return true
          },
        },
        negativeButtonProps: 'Cancel',
        onDismiss: () => {
          resolve([])
        },
      },
      { reviews: [], errors: [] },
    )
  })
  if (!reviewSelection.length) return []

  const reviewsMap = reviewSelection
  const hasReviewFromOtherGroup = reviewsMap.some((it) =>
    Object.entries(it?.groups ?? {}).any(
      ([groupId, any]) => any && groupId !== props.destinationGroupId,
    ),
  )
  const hasReviewFromPersonalList = reviewsMap.some((it) => !it?.groups)

  const confirm = await showDialog({
    title: 'Warning',
    children: () => {
      let message = 'Importing the review into this group will remove it from the personal list'
      if (hasReviewFromOtherGroup && hasReviewFromPersonalList) {
        message += ' and previous team'
      } else if (hasReviewFromOtherGroup) {
        message = 'Importing the review into this group will remove it from the previous team'
      }
      return message
    },
    user_dismissable: false,
    positiveButtonProps: 'OK',
    negativeButtonProps: 'Cancel',
  })

  return confirm ? reviewsMap : []
}
export function AddReviewsToGroupContent(props: {
  firebaseDb: FirebaseDb
  user: User
  navigate: (url: string, opts?: NavigateOptions) => void
  returnToAddReviewsDialog?: () => void
  destinationGroupId: string
  onSelectionChanged: (reviewIds: ReviewSelection[], errors?: string[]) => void
}) {
  const { orderedReviews } = useRecentsReviewStore(
    props.firebaseDb,
    props.user,
    props.destinationGroupId,
  )
  const { groupDetails: detailsStore } = useGroupDetailsStore(
    props.firebaseDb,
    props.destinationGroupId,
  )
  const groupSelectionStore = useGroupSelectionStore({
    firebaseDb: props.firebaseDb,
    user: props.user,
    defaultSelection: { type: 'option', groupId: undefined },
  })
  const { value: enableTextInput } = useFeatureFlag('textinputreviews', true, true)
  const alreadySelected = useMemo(
    () =>
      orderedReviews?.map((it) => ({
        reviewId: it.reviewId,
        documentPermissions: it.mode ?? 'edit',
      })),
    [orderedReviews],
  )
  const { onSelectionChanged } = props
  const [importErrors, setImportErrors] = useState<string[]>([])
  const handleSubmitUrls = useCallback(
    async (text: string) => {
      const urls = text.split(/[\s,]+/)
      const processedUrls = await Promise.all(
        urls.map(async (url) => {
          if (!url) return undefined
          let review = parseEditorUrl(url)
          if (!review) {
            const newUrl = await checkDynamicLinkRedirect(url)
            review = newUrl ? parseEditorUrl(newUrl) : undefined
          }
          return [url, review] as const
        }),
      ).then((it) => it.filterNotNull())

      setImportErrors(processedUrls.filter(([_, review]) => !review).map(([url, _]) => url))
      onSelectionChanged(
        processedUrls.mapNotNull(([url, review]) =>
          review ?
            {
              reviewId: review.id,
              documentPermissions: review.mode,
            }
          : undefined,
        ),
      )
    },
    [onSelectionChanged],
  )

  return (
    <>
      Select Playback Reviews to be added to {detailsStore?.name}
      <br />
      <br />
      <ScrollBox
        direction={'vertical'}
        className='max-h-[60svh] max-w-[80svw]'>
        {enableTextInput && (
          <>
            <SubmittableTextArea onSubmit={handleSubmitUrls} />
            <br />
          </>
        )}
        {importErrors.length > 0 && (
          <span className='touch-auto select-text text-playback-crimson'>
            The following URLs could not be processed: {importErrors.join(', ')}
          </span>
        )}
        <GroupsList
          firebaseDb={props.firebaseDb}
          groupSelectionStore={groupSelectionStore}
          excludeGroupId={props.destinationGroupId}
          hideCreateNewTeam={true}
          showPersonal={true}
        />
        <br />
        <ReviewsSelectionList
          detailsStore={detailsStore}
          user={props.user}
          // We want to add reviews from outside the group
          groupId={groupSelectionStore.selectedGroupId ?? undefined}
          userGroups={groupSelectionStore.groupIds}
          selected={alreadySelected}
          firebaseDb={props.firebaseDb}
          onSelectionChanged={props.onSelectionChanged}
        />
      </ScrollBox>
    </>
  )
}

export type StatRecordApplyStrategy = 'replace' | 'link' | 'upgrade_link' | 'open'

function ExistingReview(props: {
  proposedStatsRecordId: string | undefined
  firebaseDb: FirebaseDb
  review: RecentsFirebaseEntry
  user: User | undefined
  selectedReviewId: string | undefined
  onSelected: (reviewId: {
    reviewId: string
    documentPermissions: DocumentPermissions
    strategy: StatRecordApplyStrategy
    // Direct means the user's intention is clear and doesn't require another click to confirm
    // Implicit means it should require one more click to confirm the users intention
    intentionLevel: 'direct' | 'implicit'
  }) => void
}) {
  const { review } = props
  const metaStore = useReviewMetaStore(
    props.firebaseDb,
    props.user,
    review.reviewId,
    undefined,
    undefined,
    undefined,
  )

  const strategy = usePromiseState<StatRecordApplyStrategy | 'open'>(async () => {
    if (
      props.proposedStatsRecordId &&
      !metaStore.parentStatRecord &&
      !metaStore.statRecordAlias?.allTeams
    ) {
      return 'link'
    }

    if (
      props.proposedStatsRecordId &&
      !metaStore.statRecordAlias?.allTeams &&
      metaStore.parentStatRecord &&
      metaStore.parentStatRecord !== props.proposedStatsRecordId
    ) {
      const parentStatRecordEntry = await getStatRecord({
        firebase: props.firebaseDb,
        statsRecordId: metaStore.parentStatRecord,
      })
      const proposedStatRecordEntry = await getStatRecord({
        firebase: props.firebaseDb,
        statsRecordId: props.proposedStatsRecordId,
      })

      if (
        parentStatRecordEntry &&
        proposedStatRecordEntry &&
        parentStatRecordEntry?.reviewId === proposedStatRecordEntry?.reviewId
      ) {
        const existingVisibleTeams = matchingStatRecordTeamRules(parentStatRecordEntry, 'visible')
        const proposedVisibleTeams = existingVisibleTeams
          .concat(matchingStatRecordTeamRules(proposedStatRecordEntry, 'visible'))
          .distinct()

        if (proposedVisibleTeams.length > existingVisibleTeams.length) return 'upgrade_link'
      }
      return 'replace'
    }
    return 'open'
  }, [metaStore])

  return (
    <Flex
      key={review.reviewId}
      dir={'row'}
      alignItems={'center'}
      gap={8}
      padding={4}
      border={props.selectedReviewId === review.reviewId ? '2px solid white' : undefined}>
      <ReviewTile<RecentsFirebaseEntry>
        key={review.reviewId}
        entry={review}
        user={props.user}
        onClick={
          strategy &&
          (() =>
            props.onSelected({
              reviewId: review.reviewId,
              documentPermissions: review.mode ?? 'edit',
              strategy,
              intentionLevel: 'implicit',
            }))
        }
        firebaseDb={props.firebaseDb}
      />
      <Flex
        gap={8}
        fontFamily={'LeagueSpartan'}
        style={{
          fontVariationSettings: `'wght' 400`,
          fontSize: 14,
        }}
        w={100}
        alignItems={'center'}
        justifyContent={'center'}>
        {strategy === 'link' && (
          <RoundButton
            className='h-fit'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'link',
                intentionLevel: 'direct',
              })
            }>
            Link Stats
          </RoundButton>
        )}
        {strategy === 'upgrade_link' && (
          <RoundButton
            className='h-fit animate-rainbowBorder rounded-full border-[2px] border-solid'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'upgrade_link',
                intentionLevel: 'direct',
              })
            }>
            Combine Stats
          </RoundButton>
        )}
        {strategy === 'replace' && (
          <RoundButton
            className='h-fit whitespace-nowrap'
            color={Colors.color_black}
            backgroundColor={Colors.color_comments_orange}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'replace',
                intentionLevel: 'direct',
              })
            }>
            Replace Link
          </RoundButton>
        )}
        {strategy === 'open' && (
          <RoundButton
            className='h-fit whitespace-nowrap'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'open',
                intentionLevel: 'direct',
              })
            }>
            Open
          </RoundButton>
        )}
      </Flex>
    </Flex>
  )
}

export function ExistingReviewsList(props: {
  firebaseDb: FirebaseDb
  reviews: RecentsFirebaseEntry[]
  user: User | undefined
  statsRecordId: string | undefined
  selectedReviewId: string | undefined
  onSelected: (reviewId: {
    reviewId: string
    documentPermissions: DocumentPermissions
    strategy: StatRecordApplyStrategy
    // Direct means the user's intention is clear and doesn't require another click to confirm
    // Implicit means it should require one more click to confirm the users intention
    intentionLevel: 'direct' | 'implicit'
  }) => void
}) {
  return (
    <Flex
      direction={'column'}
      gap={8}>
      {props.reviews.map((review) => {
        return (
          <ExistingReview
            key={review.reviewId}
            proposedStatsRecordId={props.statsRecordId}
            firebaseDb={props.firebaseDb}
            review={review}
            user={props.user}
            selectedReviewId={props.selectedReviewId}
            onSelected={props.onSelected}
          />
        )
      })}
    </Flex>
  )
}

export function ReviewsSelectionList(props: {
  detailsStore: FirebaseGroupDetails | undefined
  firebaseDb: FirebaseDb
  groupId?: string
  userGroups: string[]
  user: User | undefined
  selected?: ReviewSelection[]
  onSelectionChanged: (reviewIds: ReviewSelection[]) => void
}) {
  const [showSearch, setShowSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const reviewStore = useRecentsReviewStore(props.firebaseDb, props.user, props.groupId)
  const [selected, setInternalSelected] = useState<ReviewSelection[]>(props.selected ?? [])
  const setSelected = useCallback(
    (a: SetStateAction<ReviewSelection[]>) => {
      if (typeof a === 'function') {
        setInternalSelected((it) => {
          const newIt = a(it)

          props.onSelectionChanged(newIt)
          return newIt
        })
        return
      }
      setInternalSelected(a)
      props.onSelectionChanged(a)
    },
    [props],
  )

  const getAccessReview = useCallback(
    async (reviewId: string) => {
      const showErrorToast = () => {
        toast(`The review could not be imported to ${props.detailsStore?.name}`, {
          type: 'error',
          position: 'bottom-right',
        })
      }

      // review from personal list
      if (!props.groupId) {
        const groupsHasReview = await props.firebaseDb.getVal(`reviews/${reviewId}/groups`)
        if (!groupsHasReview) return true

        const allowToImportReview = Object.keys(groupsHasReview ?? {}).every((groupId) => {
          return props.userGroups.includes(groupId)
        })

        if (!allowToImportReview) {
          showErrorToast()
          return false
        }

        return true
      }

      return true
    },
    [props.detailsStore?.name, props.firebaseDb, props.groupId, props.userGroups],
  )

  const handleOnToggleChange = useCallback(
    async ({ reviewId, documentPermissions }: ReviewSelection, checked: boolean) => {
      return checked ? await getAccessReview(reviewId) : true
    },
    [getAccessReview],
  )

  return (
    <Flex
      direction={'column'}
      gap={8}>
      {!showSearch && (
        <RoundButton
          className='w-fit self-center'
          icon={searchIcon}
          alt={''}
          backgroundColor={Colors.color_playback_crimson}
          color={'white'}
          iconInvert={true}
          onClick={() => setShowSearch(true)}>
          Search
        </RoundButton>
      )}
      {showSearch && (
        <SearchInput
          autoFocus={true}
          onKeyDown={(e) => setSearchTerm(e.currentTarget.value)}
          placeholder={'Search'}
          submitText={'Search'}
          onSubmit={(e) => setSearchTerm(e)}
          onClose={() => {
            setShowSearch(false)
            setSearchTerm('')
          }}
        />
      )}

      {reviewStore.orderedReviews === undefined && <ThreeDots className='w-full' />}
      <MultiSelect<ReviewSelection>
        selected={selected}
        setSelected={setSelected}
        getKey={(it) => it.reviewId}
        className='w-full'
        onToggleChanged={handleOnToggleChange}
        options={(toggleSelection) =>
          (reviewStore.orderedReviews ?? [])
            .filter(
              (entry, index) =>
                !searchTerm ||
                entry.reviewId.includes(searchTerm) ||
                entry.videoId?.includes(searchTerm) ||
                entry.title?.toLowerCase()?.includes(searchTerm.toLowerCase()),
            )
            .map((review) => {
              const alreadyAdded = props.selected?.some(
                ({ reviewId }) => reviewId === review.reviewId,
              )
              const reviewSelection = {
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
              }
              return {
                value: reviewSelection,
                disabled: alreadyAdded,
                children: (
                  <>
                    {alreadyAdded && (
                      <div className='absolute z-10 flex h-full w-full items-center justify-center text-white'>
                        <span className='rounded-full bg-slate-800/70 px-3 py-1'>
                          Already added
                        </span>
                      </div>
                    )}
                    <div className={cn('w-full', alreadyAdded && 'opacity-30')}>
                      <ReviewTile<RecentsFirebaseEntry>
                        key={review.reviewId}
                        onClick={!alreadyAdded ? () => toggleSelection(reviewSelection) : undefined}
                        entry={review}
                        user={props.user}
                        firebaseDb={props.firebaseDb}
                        className=''
                      />
                    </div>
                  </>
                ),
              }
            })
        }
      />
    </Flex>
  )
}

function SubmittableTextArea(props: { onSubmit: (text: string) => void }) {
  const [showTextArea, setShowTextArea] = useState(false)

  return (
    <>
      {showTextArea ?
        <div>
          <textarea
            className='h-fit w-full'
            onChange={(e) => props.onSubmit(e.currentTarget.value)}
            onPaste={(e) => props.onSubmit(e.currentTarget.value)}
          />
          <RoundButton onClick={() => setShowTextArea(false)}>Cancel</RoundButton>
        </div>
      : <RoundButton
          className='bg-playback-crimson text-white'
          onClick={() => setShowTextArea(true)}>
          Import review links
        </RoundButton>
      }
    </>
  )
}

export const parseEditorUrl = (
  url: string,
): { id: string; mode: DocumentPermissions } | undefined => {
  try {
    const o = new URL(url)
    // there was a bug introducing % in the URL, this is a workaround to fix it
    const id = o.searchParams.get('id')?.split('%')?.firstOrNull()
    const mode = o.searchParams.get('mode')

    if (id && mode && (mode === 'view' || mode === 'edit')) {
      return { id, mode: mode as DocumentPermissions }
    }
    return undefined
  } catch (error) {
    console.error('Invalid URL:', error)
    return undefined
  }
}

export async function checkDynamicLinkRedirect(url: string): Promise<string | undefined> {
  try {
    const newUrl = new URL(url)
    newUrl.searchParams.set('_imcp', '1')
    const headResponse = await fetch(newUrl.toString(), { method: 'HEAD', redirect: 'follow' })
    return headResponse.url
  } catch (error) {
    console.error('Error checking URL for redirect:', error)
  }
}
