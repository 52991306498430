import { DEFINED, Definitions } from 'templates/Definitions'
import {
  BaseDefinition,
  EventDimension,
  EventDimensionsDefinition,
  OptionsByDimension,
  VariantKeyByDimension,
  createBaseDefinition,
  createTypedEventDefinition,
  generateEventDefinitions,
  generateHeirarchy,
} from 'templates/MetaEvents'
import {
  ButtonConfig,
  EventDefinition,
  LayeredTypedButtonConfig,
  StatCategory,
  TypedButtonConfig,
  createButtonConfig,
  mergeArrayMaps,
} from 'templates/TemplateConfig'
import DodgeballIcons from 'templates/dodgeball/DodgeballIcons'
import { Colors } from '../../Colors'
import DodgeballTemplateLocales from './DodgeballTemplateLocales'

import attack_icon = DodgeballIcons.attack_icon
import revive_icon = DodgeballIcons.revive_icon
import color_white = Colors.color_white
import defend_icon = DodgeballIcons.defend_icon
import color_blue = Colors.color_blue
import set_up_kill_icon = DodgeballIcons.set_up_kill_icon
import color_red = Colors.color_red
import setthrow_icon = DodgeballIcons.setthrow_icon
import trade_icon = DodgeballIcons.trade_icon
import punish_icon = DodgeballIcons.punish_icon
import counter_rush_icon = DodgeballIcons.counter_rush_icon
import opp_shot_icon = DodgeballIcons.opp_shot_icon
import hit_icon = DodgeballIcons.hit_icon
import miss_icon = DodgeballIcons.miss_icon
import ontarget_icon = DodgeballIcons.ontarget_icon
import catch_icon = DodgeballIcons.catch_icon
import dodge_icon = DodgeballIcons.dodge_icon
import block_icon = DodgeballIcons.block_icon
import died_icon = DodgeballIcons.died_icon
import catch_died_icon = DodgeballIcons.catch_died_icon
import trade_died_icon = DodgeballIcons.trade_died_icon
import foot_foul_died_icon = DodgeballIcons.foot_foul_died_icon
import attack_died_icon = DodgeballIcons.attack_died_icon

export type ThrowResultDimension = ['throw_result', 'hit' | 'miss' | 'ontarget']
export type ThrowTypeDimension = [
  'throw_type',
  'set-throw' | 'opp-shot' | 'counter-rush' | 'punish' | 'trade' | 'pre-throw',
]
export type ThrowDimensions = ThrowResultDimension | ThrowTypeDimension
export type ThrowKey = `${ThrowTypeKey}${`_${ThrowResultDimension[1]}` | ''}`
export type ThrowTypeKey = `throw${`_${ThrowTypeDimension[1]}` | ''}`
export const throws = createBaseDefinition<ThrowKey, ThrowDimensions>({
  baseKey: 'throw',
  title: DodgeballTemplateLocales.titleLocalGetter('throw'),
  baseIcon: attack_icon,
  keyOrder: { throw_type: 0, throw_result: 1 },
  disableTrackFor: (base, options, variantKeyByDimension) => !variantKeyByDimension.throw_result,
  dimensions: {
    throw_type: {
      'set-throw': {
        title: DodgeballTemplateLocales.titleLocalGetter('set-throw'),
        modifierIcon: setthrow_icon,
      },
      'opp-shot': {
        title: DodgeballTemplateLocales.titleLocalGetter('opp-shot'),
        modifierIcon: opp_shot_icon,
      },
      'counter-rush': {
        title: DodgeballTemplateLocales.titleLocalGetter('counter-rush'),
        modifierIcon: counter_rush_icon,
        description: DodgeballTemplateLocales.descriptionLocaleGetter('counter-rush'),
      },
      punish: {
        title: DodgeballTemplateLocales.titleLocalGetter('punish'),
        modifierIcon: punish_icon,
        description: DodgeballTemplateLocales.descriptionLocaleGetter('punish'),
      },
      trade: {
        title: DodgeballTemplateLocales.titleLocalGetter('trade'),
        modifierIcon: trade_icon,
        description: DodgeballTemplateLocales.descriptionLocaleGetter('trade'),
      },
      'pre-throw': {
        title: DodgeballTemplateLocales.titleLocalGetter('pre-throw'),
      },
    },
    throw_result: {
      hit: {
        title: DodgeballTemplateLocales.titleLocalGetter('hit'),
        modifierIcon: hit_icon,
        modifierColor: Colors.color_red,
      },
      miss: {
        title: DodgeballTemplateLocales.titleLocalGetter('miss'),
        modifierIcon: miss_icon,
        modifierColor: Colors.color_orange,
      },
      ontarget: {
        title: DodgeballTemplateLocales.titleLocalGetter('ontarget'),
        childVariant: 'hit',
        modifierIcon: ontarget_icon,
        modifierColor: Colors.color_blue,
      },
    },
  },
} as const)

export type DefenceResultDimension = ['defend_result', 'failed-died' | 'failed' | 'success']
export type DefenceTypeDimension = ['defend_type', 'block' | 'dodge' | 'catch']
export type DefenceDimensions = DefenceResultDimension | DefenceTypeDimension
export type DefenceKey =
  `defend${`_${DefenceTypeDimension[1]}` | ''}${`_${DefenceResultDimension[1]}` | ''}`
const defend = createBaseDefinition<DefenceKey, DefenceDimensions>({
  baseKey: 'defend',
  title: DodgeballTemplateLocales.titleLocalGetter('defend'),
  baseIcon: defend_icon,
  keyOrder: { defend_type: 0, defend_result: 1 },
  iconOrder: { defend_result: -1, defend_type: 0 },
  disableTrackFor: (base, options, variantKeyByDimension) => !variantKeyByDimension.defend_result,
  dimensions: {
    defend_type: {
      block: {
        title: DodgeballTemplateLocales.titleLocalGetter('block'),
        modifierIcon: block_icon,
      },
      dodge: {
        title: DodgeballTemplateLocales.titleLocalGetter('dodge'),
        modifierIcon: dodge_icon,
      },
      catch: {
        title: DodgeballTemplateLocales.titleLocalGetter('catch'),
        modifierIcon: catch_icon,
      },
    } as const,
    defend_result: {
      'failed-died': {
        title: DodgeballTemplateLocales.titleLocalGetter('failed-died'),
        modifierColor: Colors.color_black,
        modifierIcon: {
          src: died_icon,
          // opacity: '80%',
          // #491f52
          // use https://isotropic.co/tool/hex-color-to-css-filter/ to get the filter
          filter:
            'invert(16%) sepia(37%) saturate(1291%) hue-rotate(247deg) brightness(92%) contrast(99%)',
        },
      },
      failed: {
        title: DodgeballTemplateLocales.titleLocalGetter('failed'),
        childVariant: 'failed-died',
        modifierColor: Colors.color_orange,
      },
      success: {
        title: DodgeballTemplateLocales.titleLocalGetter('success'),
        modifierColor: Colors.color_green,
      },
    } as const,
  },
} as const)

type OtherDeathsDimension = [
  'death_type',
  'noaction' | 'catch-reach' | 'while-throwing' | 'got-caught' | 'foot-foul' | 'hit',
]
type OtherDeathsKeys = `died${`_${OtherDeathsDimension[1]}` | ''}`
const otherDeaths = createBaseDefinition<OtherDeathsKeys, OtherDeathsDimension>({
  baseKey: 'died',
  title: DodgeballTemplateLocales.titleLocalGetter('died'),
  keyOrder: { death_type: 0 },
  dimensions: {
    death_type: {
      noaction: {
        title: DodgeballTemplateLocales.titleLocalGetter('death-type-noaction'),
        description: 'They barely dodged or blocked. Most likely from not seeing the ball coming.',
      },
      'catch-reach': {
        title: DodgeballTemplateLocales.titleLocalGetter('catch-reach'),
        modifierIcon: catch_died_icon,
      },
      'while-throwing': {
        title: DodgeballTemplateLocales.titleLocalGetter('while-throwing'),
        modifierIcon: trade_died_icon,
        description: DodgeballTemplateLocales.descriptionLocaleGetter('while-throwing'),
      },
      'got-caught': {
        title: DodgeballTemplateLocales.titleLocalGetter('got-caught'),
        modifierIcon: attack_died_icon,
      },
      'foot-foul': {
        title: DodgeballTemplateLocales.titleLocalGetter('foot-foul'),
        modifierIcon: foot_foul_died_icon,
      },
      hit: {
        title: DodgeballTemplateLocales.titleLocalGetter('died-hit'),
        childVariant: 'noaction' /* while-throwing */,
      },
    } as const,
  },
  baseIcon: died_icon,
  baseColor: Colors.color_black,
} as const)
const definition_revive = createTypedEventDefinition({
  key: 'revive',
  icon: revive_icon,
  title: DodgeballTemplateLocales.titleLocalGetter('revive'),
  color: color_white,
  dataTags: ['Caught In'],
})
const definition_got_targeted = createTypedEventDefinition({
  key: 'got_targeted',
  icon: defend_icon,
  title: DodgeballTemplateLocales.titleLocalGetter('got_targeted'),
  color: color_blue,
  dataTags: ['Defend - Targeted'],
  description: DodgeballTemplateLocales.descriptionLocaleGetter('got_targeted'),
  getOpposing(): EventDefinition[] {
    return [defs.get('throw_miss')]
  },
})
const definition_set_up = createTypedEventDefinition({
  key: 'set_up_success',
  icon: set_up_kill_icon,
  title: DodgeballTemplateLocales.titleLocalGetter('set_up_success'),
  color: color_red,
  dataTags: ['Set-Up Success'],
  description: "When a player's fake results in a kill",
})
export const opposingFromThrow = (
  base: BaseDefinition<ThrowKey, ThrowDimensions>,
  optionsByDimension: OptionsByDimension<ThrowDimensions>,
  variantKeyByDimension: VariantKeyByDimension<ThrowDimensions>,
): EventDefinition | EventDefinition[] | undefined => {
  if (variantKeyByDimension.throw_result === 'hit') return [_Dodgeball.BaseDefinitions.get('died')]
  if (variantKeyByDimension.throw_result === 'ontarget')
    return [_Dodgeball.BaseDefinitions.get('defend_block_success')]
  return [_Dodgeball.BaseDefinitions.get('got_targeted')]
}
export const opposingFromDefence = (
  base: BaseDefinition<DefenceKey, DefenceDimensions>,
  optionsByDimension: OptionsByDimension<DefenceDimensions>,
  variantKeyByDimension: VariantKeyByDimension<DefenceDimensions>,
): EventDefinition | EventDefinition[] | undefined => {
  if (variantKeyByDimension.defend_result === 'failed-died')
    return _Dodgeball.BaseDefinitions.get('throw_hit')
  if (variantKeyByDimension.defend_result === 'failed')
    return _Dodgeball.BaseDefinitions.get('throw_ontarget')
  if (
    variantKeyByDimension.defend_result === 'success' &&
    variantKeyByDimension.defend_type === 'block'
  )
    return _Dodgeball.BaseDefinitions.get('throw_ontarget')
  if (
    variantKeyByDimension.defend_result === 'success' &&
    variantKeyByDimension.defend_type === 'dodge'
  )
    return _Dodgeball.BaseDefinitions.get('throw_miss')
  if (
    variantKeyByDimension.defend_result === 'success' &&
    variantKeyByDimension.defend_type === 'catch'
  )
    return [_Dodgeball.BaseDefinitions.get('died_got-caught')]

  return _Dodgeball.BaseDefinitions.get('throw_miss')
}

export const opposingFromDeath = (
  base: BaseDefinition<OtherDeathsKeys, OtherDeathsDimension>,
  optionsByDimension: OptionsByDimension<OtherDeathsDimension>,
  variantKeyByDimension: VariantKeyByDimension<OtherDeathsDimension>,
): EventDefinition | EventDefinition[] | undefined => {
  if (variantKeyByDimension.death_type === 'foot-foul') return undefined
  if (variantKeyByDimension.death_type === 'got-caught')
    return [_Dodgeball.BaseDefinitions.get('defend_catch_success')]
  return _Dodgeball.BaseDefinitions.get('throw_hit')
}

const defs = new Definitions(
  [definition_revive, definition_got_targeted, definition_set_up]
    .concatUnion(
      generateEventDefinitions({
        baseDefinition: throws,
        opposing: opposingFromThrow,
      }),
    )
    .concatUnion(
      generateEventDefinitions({
        baseDefinition: defend,
        opposing: opposingFromDefence,
      }),
    )
    .concatUnion(
      generateEventDefinitions({
        baseDefinition: otherDeaths,
        opposing: opposingFromDeath,
      }),
    ),
)

const customHierarchy = new Map<StatCategory, StatCategory[]>([
  ['KillEvent', [defs.get('throw_hit'), defs.get('defend_catch_success')]],
  [defs.get('got_targeted'), [defs.get('defend'), defs.get('died_hit')]],
  [
    'BlockResult',
    [
      defs.get('defend_block_success'),
      defs.get('defend_block_failed'),
      defs.get('defend_block_failed-died'),
    ],
  ],
  [
    'DodgeResult',
    [
      defs.get('defend_dodge_success'),
      defs.get('defend_dodge_failed'),
      defs.get('defend_dodge_failed-died'),
    ],
  ],
  [
    'CatchResult',
    [
      defs.get('defend_catch_success'),
      defs.get('defend_catch_failed'),
      defs.get('defend_catch_failed-died'),
    ],
  ],
  [
    'DefenseResult',
    [defs.get('defend_success'), defs.get('defend_failed'), defs.get('defend_failed-died')],
  ],
  [defs.get('defend_failed-died'), [defs.get('died_catch-reach')]],
  ['Assistable', [defs.get('defend_catch'), defs.get('throw')]],
  [
    defs.get('died_hit'),
    [defs.get('died_noaction'), defs.get('defend_failed-died'), defs.get('died_while-throwing')],
  ],
])

function generateDodgeballStatHierarchy<T extends typeof defs>(
  defs: T,
  newThrowDimensions?: Partial<
    EventDimensionsDefinition<EventDimension<ThrowDimensions[0], string>>
  >,
) {
  let hierarchy = mergeArrayMaps(
    customHierarchy,
    generateHeirarchy({
      baseDefinition: throws,
      newDimensions: newThrowDimensions,
      eventDefinitions: defs.asArray(),
    }),
  )
  hierarchy = mergeArrayMaps(
    hierarchy,
    generateHeirarchy({
      baseDefinition: defend,
      eventDefinitions: defs.asArray(),
    }),
  )
  hierarchy = mergeArrayMaps(
    hierarchy,
    generateHeirarchy({
      baseDefinition: otherDeaths,
      eventDefinitions: defs.asArray(),
    }),
  )
  return hierarchy
}

function generateDodgeballButtonConfig<T extends typeof defs>(defs: T) {
  return new Map<EventDefinition, LayeredTypedButtonConfig>([
    [
      defs.get('died'),
      {
        default: [
          ...defs
            .getAll(
              'died_noaction',
              'died_got-caught',
              'defend_failed-died',
              'died_foot-foul',
              'died_while-throwing',
            )
            .asButtonConfigs(defs),
        ],
        basic: [
          ...defs
            .getAll(undefined, 'died_got-caught', 'died_hit', 'died_foot-foul', undefined)
            .asButtonConfigs(defs),
        ],
        kdr: [
          ...defs
            .getAll(undefined, 'died_got-caught', 'died_hit', 'died_foot-foul', undefined)
            .asButtonConfigs(defs),
        ],
      },
    ],
    [
      defs.get('defend'),
      {
        default: [
          ...defs.getAll('defend_dodge', 'got_targeted', 'defend_block').asButtonConfigs(defs),
        ],
        basic: [
          ...defs
            .getAll('defend_dodge_success', 'got_targeted', 'defend_block_success')
            .asButtonConfigs(defs),
        ],
      },
    ],
    ...defs.filterMap({ defend_result: null, defend_type: DEFINED }, (key, def, it) => {
      const defendResults = defs.filterTyped({
        defend_type: it.descriptor.defend_type,
        defend_result: DEFINED,
      })
      const reducedDefendResults = defendResults.map((it) =>
        it.descriptor.defend_result === 'failed-died' || it.descriptor.defend_result === 'success' ?
          it
        : undefined,
      )
      return [
        def,
        {
          default: defendResults.asButtonConfigs(defs),
          basic: reducedDefendResults.asButtonConfigs(defs),
          kdr: reducedDefendResults.asButtonConfigs(defs),
        } satisfies LayeredTypedButtonConfig,
      ] as const
    }),
    [
      defs.get('throw'),
      {
        default: [
          ...defs
            .filter({ throw_type: null, throw_result: DEFINED })
            .concat(defs.get('set_up_success'))
            .asButtonConfigs(defs),
        ],
        basic: [...defs.filter({ throw_type: null, throw_result: DEFINED }).asButtonConfigs(defs)],
        kdr: [...defs.filter({ throw_type: null, throw_result: 'hit' }).asButtonConfigs(defs)],
      },
    ],
    ...defs.filterMap(
      { throw_type: null, throw_result: DEFINED },
      (key, def, it) =>
        [
          def,
          {
            default: defs
              .filter({
                throw_result: it.descriptor.throw_result,
                throw_type: DEFINED,
              })
              .asButtonConfigs(defs),
            basic: [],
            kdr: [],
          } satisfies LayeredTypedButtonConfig,
        ] as const,
    ),
    ...defs.filterMap({ throw_result: null, throw_type: DEFINED }, (key, def, it) => {
      return [
        def,
        {
          default: defs
            .filter({
              throw_type: it.descriptor.throw_type,
              throw_result: DEFINED,
            })
            .asButtonConfigs(defs),
          basic: [],
          kdr: [],
        } satisfies LayeredTypedButtonConfig,
      ] as const
    }),
    ...defs.filterMap(
      { throw_result: DEFINED, throw_type: 'trade' },
      (key, def, it) =>
        [
          def,
          {
            default: [
              createButtonConfig({
                definition: def,
                titleOverride: def.title + ' And Died',
                followUps: [
                  {
                    event: defs.get('died_while-throwing'),
                    playerAssignment: 'same-person',
                  },
                ],
                iconOverride: defs.get('died_while-throwing').icon,
                colorOverride: defs.get('died_while-throwing').color,
                shortcut: 'q',
              }),
            ],
            basic: [],
            kdr: [],
          } satisfies LayeredTypedButtonConfig,
        ] as const,
    ),
    [
      defs.get('defend_catch_failed-died'),
      {
        default: [
          createButtonConfig({
            definition: defs.get('died_catch-reach'),
            shortcut: 'q',
          }),
        ],
        basic: [],
        kdr: [],
      } satisfies LayeredTypedButtonConfig,
    ],
  ])
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace _Dodgeball {
  export const BaseDefinitions = defs
  export const generateStatHierarchy = generateDodgeballStatHierarchy
  export const generateButtonConfig = generateDodgeballButtonConfig
}
